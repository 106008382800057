import {EuiPageTemplate} from '@elastic/eui';
import {FluentProvider, teamsLightTheme} from '@fluentui/react-components';
import {app} from '@microsoft/teams-js';
import {useTeamsUserCredential} from '@microsoft/teamsfx-react';
import {useEffect} from 'react';
import {BrowserRouter} from 'react-router-dom';

import config from '../common/config';
import {ToastProvider} from '../contexts/ToastContext';
import {DefaultLayout} from '../layouts/DefaultLayout';
import {TeamsFxContext} from './TeamsFxContext';

export const App = () => {
  const {loading, theme, themeString, teamsUserCredential} =
    useTeamsUserCredential({
      initiateLoginEndpoint: config.initiateLoginEndpoint!,
      clientId: config.clientId!,
    });

  useEffect(() => {
    loading &&
      app.initialize().then(() => {
        app.notifySuccess();
      });
  }, [loading]);

  return (
    <TeamsFxContext.Provider value={{theme, themeString, teamsUserCredential}}>
      <EuiPageTemplate
        className="tcf-page"
        panelled={false}
        offset={0}
        restrictWidth={false}
        bottomBorder={false}
      >
        <ToastProvider>
          <EuiPageTemplate.Section className="tcf-transparent-background">
            <FluentProvider
              theme={teamsLightTheme}
              style={{backgroundColor: 'transparent'}}
            >
              {!loading && (
                <BrowserRouter>
                  <DefaultLayout />
                </BrowserRouter>
              )}
            </FluentProvider>
          </EuiPageTemplate.Section>
        </ToastProvider>
      </EuiPageTemplate>
    </TeamsFxContext.Provider>
  );
};
