import {
  EuiButtonEmpty,
  EuiContextMenu,
  EuiIcon,
  EuiPopover,
  useGeneratedHtmlId,
} from '@elastic/eui';
import {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {PageOptions} from '../common/interfaces';
import {useEasyPageOptionsProvider} from '../contexts/EasyPageOptionsContext';
import {useEasySearchProvider} from '../contexts/EasySearchContext';

export const OptionsMenu = () => {
  const {t} = useTranslation();
  const [isPopoverOpen, setPopover] = useState(false);
  const {pageOptions, setPageOptions} = useEasyPageOptionsProvider();
  const {selectedView, setSelectedView} = useEasySearchProvider();

  const smallContextMenuPopoverId = useGeneratedHtmlId({
    prefix: 'smallContextMenuPopover',
  });

  const onButtonClick = () => {
    setPopover(!isPopoverOpen);
  };

  const closePopover = () => {
    setPopover(false);
  };

  const handlePageViewOptionChange = (newPageOptions: PageOptions) => {
    setPageOptions(newPageOptions);

    if (selectedView) {
      setSelectedView({
        ...selectedView,
        pageOptions: newPageOptions,
      });
    }
  };

  const panels = [
    {
      id: 0,
      title: t('Main.Options.Name'),
      items: [
        {name: t('Main.Options.GroupBy'), icon: 'folderOpen', panel: 1},
        {
          name: pageOptions.presenceView
            ? t('Main.Options.EnableStandardView')
            : t('Main.Options.EnablePresenceView'),
          icon: `${pageOptions.presenceView ? 'user' : 'timeRefresh'}`,
          onClick: () => {
            handlePageViewOptionChange({
              ...pageOptions,
              presenceView: !pageOptions.presenceView,
            });
          },
        },
        {
          name: pageOptions.fullScreen
            ? t('Main.Options.DisableFullscreenResult')
            : t('Main.Options.EnableFullscreenResult'),
          icon: `${pageOptions.fullScreen ? 'check' : 'fullScreen'}`,
          onClick: () => {
            handlePageViewOptionChange({
              ...pageOptions,
              fullScreen: !pageOptions.fullScreen,
            });
          },
        },
        {
          name: t('Main.Options.BulkTagging'),
          icon: 'share',
          onClick: closePopover,
          disabled: true,
        },
        {
          name: t('Main.Options.RemoveGrouping'),
          icon: <EuiIcon type="trash" color="red" />,
          onClick: () => {
            handlePageViewOptionChange({
              ...pageOptions,
              groupedBy: undefined,
            });
          },
          disabled: !pageOptions.groupedBy?.value,
        },
      ],
    },
    {
      id: 1,
      title: t('Main.Options.GroupBy'),
      items: [
        {
          name: t('Main.Options.Group.Tags'),
          icon: 'tag',
          onClick: () => {
            handlePageViewOptionChange({
              ...pageOptions,
              groupedBy: {
                groupField: 'tags',
                value: 'tags',
              },
            });
          },
        },
        {
          name: t('Main.Options.Group.Company'),
          icon: 'home',
          onClick: () => {
            handlePageViewOptionChange({
              ...pageOptions,
              groupedBy: {
                groupField: 'company_name',
                value: 'company_name',
              },
            });
          },
        },
        {
          name: t('Main.Options.Group.Department'),
          icon: 'users',
          onClick: () => {
            handlePageViewOptionChange({
              ...pageOptions,
              groupedBy: {
                groupField: 'department',
                value: 'department',
              },
            });
          },
        },
      ],
    },
  ];

  const button = (
    <EuiButtonEmpty
      iconType="arrowDown"
      iconSide="right"
      onClick={onButtonClick}
    >
      <EuiIcon type="controlsHorizontal" /> {t('Main.Options.Name')}
    </EuiButtonEmpty>
  );

  return (
    <EuiPopover
      id={smallContextMenuPopoverId}
      button={button}
      isOpen={isPopoverOpen}
      closePopover={closePopover}
      panelPaddingSize="none"
      anchorPosition="downCenter"
    >
      <EuiContextMenu initialPanelId={0} panels={panels} />
    </EuiPopover>
  );
};
