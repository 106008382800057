import {HubConnectionBuilder} from '@microsoft/signalr';

export class SignalRService {
  signalRService: any;
  hubUrl: string;
  voiceAppId: string;

  constructor(hubUrl: string, voiceAppId: string) {
    this.hubUrl = hubUrl;
    this.voiceAppId = voiceAppId;
  }

  public startConnection(): void {
    this.signalRService.connection = new HubConnectionBuilder()
      .withUrl(this.hubUrl)
      .withAutomaticReconnect()
      .build();

    this.signalRService.connection
      ?.start()
      .then(() => {
        // signalRService.connection.invoke("JoinGroup", voiceAppId)
        //     .catch(err => {
        //         console.log(err);
        //     });
        console.log('SignalR connection established.');
      })
      .catch((err: unknown) => {
        console.error('Error starting SignalR connection:', err);
      });
  }

  public stopConnection(): void {
    this.signalRService.connection?.stop();
  }

  public onReceiveMessage(callback: (arg0: any) => any): void {
    this.signalRService.connection?.on('newCall', (message: string) => {
      console.log(message);
      callback(message);
    });
  }

  public sendMessage(user: any, message: any): void {
    this.signalRService.connection
      ?.invoke('SendMessage', user, message)
      .catch((err: unknown) => {
        console.error('Error sending message:', err);
      });
  }
}
