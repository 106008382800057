import {app} from '@microsoft/teams-js';

import config from '../common/config';
import {DeepLinkActionType} from '../common/enums';

/**
 * Handles the creation and opening of deep links based on the provided action type.
 *
 * @param {string} value - The value to be used in the deep link (e.g., user ID, email, phone number).
 * @param {DeepLinkActionType} type - The type of action to perform (e.g., Teams chat, call, email).
 */
export function deepLinkHandler(value: string, type: DeepLinkActionType) {
  if (!value) {
    console.warn('No value provided for deep link.');
    return;
  }

  let deepLink: string;

  switch (type) {
    case DeepLinkActionType.TeamsChat:
      deepLink = `${config.teamsUrl}/l/chat/0/0?users=${value}`;
      break;

    case DeepLinkActionType.TeamsVideoCall:
      deepLink = `${config.teamsUrl}/l/call/0/0?users=${value}&withVideo=true`;
      break;

    case DeepLinkActionType.TeamsCall:
      deepLink = `${config.teamsUrl}/l/call/0/0?users=${value}`;
      break;

    case DeepLinkActionType.Email:
      deepLink = `mailto:${value}`;
      break;

    case DeepLinkActionType.PSTNCall: {
      const trimmed = value.replace(/\s+/g, '');
      const decoded = trimmed.replace(/\+/g, '%2b');
      deepLink = `${config.teamsUrl}/l/call/0/0?users=4:${decoded}`;
      break;
    }

    case DeepLinkActionType.GoogleMaps:
      deepLink = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(
        value,
      )}`;
      break;

    default:
      throw new Error('Not implemented action type!');
  }

  if (deepLink) {
    app.openLink(deepLink).catch((error) => {
      console.error('Failed to open deep link:', deepLink, error);
    });
  } else {
    throw new Error(`Failed to create deep link for type: ${type}`);
  }
}
