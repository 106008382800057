import {ResultViewProps} from '@elastic/react-search-ui-views';
import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  AccordionToggleEventHandler,
  Text,
} from '@fluentui/react-components';
import {useEffect, useState} from 'react';

import {UserConfiguration} from '../../common/interfaces';
import {EasyDirectoryResultView} from '../../components/EasyDirectoryResult';
import {useEasyPageOptionsProvider} from '../../contexts/EasyPageOptionsContext';
import {GroupedResult, Result, Results} from './Results';

interface GroupedResultViewProps {
  results: Results;
  config: UserConfiguration;
  searchTerm: string;
  setSearchTerm: (searchTerm: string) => void;
}

export const GroupedResultView: React.FC<GroupedResultViewProps> = ({
  results,
  config,
  searchTerm,
  setSearchTerm,
}) => {
  const {pageOptions} = useEasyPageOptionsProvider();
  const [openItems, setOpenItems] = useState<string[]>(
    results.map((r) => r._group_key),
  );

  const handleToggle: AccordionToggleEventHandler<string> = (event, data) => {
    setOpenItems(data.openItems);
  };

  const mapResultToResultViewProps = (
    result: GroupedResult,
  ): ResultViewProps => {
    return {
      result: result,
      onClickLink: () => {},
    };
  };

  useEffect(() => {
    setOpenItems(results.map((r) => r._group_key));
  }, [results]);

  const mapResultToGroupedResult = (result: Result): GroupedResult[] => {
    const parentAsGroupedResult: GroupedResult = {
      display_name: result.display_name,
      given_name: result.given_name,
      surname: result.surname,
      mail: result.mail,
      mobile_phone: result.mobile_phone,
      business_phones: result.business_phones,
      job_title: result.job_title,
      office_location: result.office_location,
      upn: result.upn,
      city: result.city,
      company_name: result.company_name,
      country: result.country,
      department: result.department,
      fax_number: result.fax_number,
      postal_code: result.postal_code,
      street_address: result.street_address,
      tags: result.tags,
      type: result.type,
      id: result.id,
      _meta: result._meta,
    };

    const groupedResults = [parentAsGroupedResult, ...result._group];

    return groupedResults;
  };

  return (
    <>
      {results && (
        <Accordion
          openItems={openItems}
          onToggle={handleToggle}
          multiple
          collapsible
        >
          {results.map((res) => (
            <AccordionItem
              key={res._group_key}
              title={res._group_key}
              value={res._group_key}
            >
              <AccordionHeader>
                {res._group_key && res._group_key.trim() !== '' ? (
                  <Text weight="bold">{res._group_key}</Text>
                ) : (
                  <Text weight="bold">Unbekannt</Text>
                )}
              </AccordionHeader>
              <AccordionPanel className="sui-results-container">
                {res._group &&
                  mapResultToGroupedResult(res).map((groupedRes) => (
                    <EasyDirectoryResultView
                      resultView={mapResultToResultViewProps(groupedRes)}
                      config={config}
                    />
                  ))}
              </AccordionPanel>
            </AccordionItem>
          ))}
        </Accordion>
      )}
    </>
  );
};
