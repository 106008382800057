// EasySaveViewModal.tsx

import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
} from '@elastic/eui';
import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

type EasySaveViewModalProps = {
  onCloseModal: () => void;
  onSave: (viewName: string) => Promise<void>;
};

const EasySaveViewModal: React.FC<EasySaveViewModalProps> = ({
  onCloseModal,
  onSave,
}) => {
  const {t} = useTranslation();
  const [viewName, setViewName] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const handleSave = async () => {
    if (viewName.trim() === '') {
      // Optionally, show an error message for empty view name
      return;
    }
    setIsSaving(true);
    try {
      await onSave(viewName);
      // The modal will be closed by the parent component after successful save
    } catch (error) {
      console.error('Error saving view:', error);
      // Optionally, show an error notification
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <EuiOverlayMask>
      <EuiModal onClose={onCloseModal} initialFocus="[name=viewName]">
        <EuiModalHeader>
          <EuiModalHeaderTitle>
            {t('Modals.SaveView.Title')}
          </EuiModalHeaderTitle>
        </EuiModalHeader>

        <EuiModalBody>
          <EuiForm>
            <EuiFormRow label={t('Modals.SaveView.Text')} fullWidth>
              <EuiFieldText
                name="viewName"
                value={viewName}
                onChange={(e) => setViewName(e.target.value)}
                fullWidth
                disabled={isSaving}
              />
            </EuiFormRow>
          </EuiForm>
        </EuiModalBody>

        <EuiModalFooter>
          <EuiButtonEmpty onClick={onCloseModal} disabled={isSaving}>
            {t('Modals.SaveView.Cancel')}
          </EuiButtonEmpty>

          <EuiButton
            onClick={handleSave}
            fill
            isLoading={isSaving}
            disabled={isSaving}
          >
            {t('Modals.SaveView.Save')}
          </EuiButton>
        </EuiModalFooter>
      </EuiModal>
    </EuiOverlayMask>
  );
};

export default EasySaveViewModal;
