import {
  Button,
  makeStyles,
  Toast,
  ToastBody,
  ToastTitle,
  tokens,
  Tooltip,
} from '@fluentui/react-components';
import {CloudCheckmarkFilled, CloudRegular} from '@fluentui/react-icons';
import {useContext, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {PersonalView} from '../common/interfaces';
import {useEasySearchProvider} from '../contexts/EasySearchContext';
import {useToast} from '../contexts/ToastContext';
import {ConfigurationService} from '../services/ConfigurationService';
import {EasyContext} from './EasyContext';

interface SynchedContactProps {
  contactId: string;
  displayName?: string;
  onSyncToggle?: () => void;
}

const useClasses = makeStyles({
  iconColor: {
    color: tokens.colorBrandForeground1,
  },
});

export const SynchedContact: React.FC<SynchedContactProps> = ({
  contactId,
  displayName,
}) => {
  const classes = useClasses();
  const {t} = useTranslation();
  const {dispatchToast} = useToast();
  const {selectedView, setSelectedView} = useEasySearchProvider();

  const [synchedIndex, setSynchedIndex] = useState<number>();
  const [isSynched, setIsSynched] = useState(false);

  const {accessToken, userConfig, userId, dispatch} = useContext(EasyContext);

  const configurationService = new ConfigurationService(accessToken);

  useEffect(() => {
    if (userConfig?.SynchedContacts) {
      const synched = userConfig.SynchedContacts.find((f) => f === contactId);
      if (synched) {
        setIsSynched(synched !== undefined);
        setSynchedIndex(
          synched
            ? userConfig.SynchedContacts.findIndex((f) => f === contactId)
            : undefined,
        );
      }
    }
  }, [userConfig?.SynchedContacts, contactId]);

  const updateSynchedContacts = async (synchedContacts: string[]) => {
    try {
      if (synchedContacts) {
        dispatch({
          type: 'UPDATE_SYNCHED_CONTACTS',
          payload: synchedContacts,
        });
      }
    } catch (error) {
      console.error('Error updating synched contacts:', error);
    }
  };

  const addSynchedContact = async () => {
    console.log('Starting add synched contact, current state:', isSynched);
    if (!contactId) {
      showToast(false, 'add');
      return;
    }

    try {
      const result = await configurationService.postSynchedUser(
        userId,
        contactId,
      );

      if (result) {
        setIsSynched(true);
        showToast(true, 'add', displayName);
      } else {
        showToast(false, 'add');
      }
    } catch (error) {
      console.error('Error adding synched contact:', error);
      showToast(false, 'add');
    }
  };

  const removeSynchedContact = async () => {
    if (synchedIndex === undefined) {
      showToast(false, 'remove');
      return;
    }

    try {
      const result = await configurationService.deleteSynchedUser(
        userId,
        contactId,
      );
      if (result) {
        setIsSynched(false);
        updateSynchedContacts(result.SynchedContacts ?? []);
        await handleSynchedView(result.SynchedContacts ?? []);
        showToast(true, 'remove', displayName);
      } else {
        showToast(false, 'remove');
      }
    } catch (error) {
      console.error('Error removing synched contact:', error);
      showToast(false, 'remove');
    }
  };

  const handleSynchedView = async (synchedIds: string[]) => {
    if (selectedView?.id === 'synched_contacts') {
      const synchedView: PersonalView = {
        id: 'synched_contacts',
        label: t('Views.Dropdown.SynchedText'),
        searchTemplate: JSON.stringify({
          searchTerm: '',
          filters: [
            {
              field: 'id',
              values: synchedIds,
              type: 'any',
            },
          ],
        }),
      };

      setSelectedView(synchedView);
    }
  };

  const showToast = (
    success: boolean,
    action: 'add' | 'remove',
    label?: string,
  ) => {
    if (success) {
      dispatchToast(
        <Toast>
          <ToastTitle>
            {action === 'add'
              ? t('Notifications.Titles.View.Synched.Added')
              : t('Notifications.Titles.View.Synched.Removed')}
          </ToastTitle>
          <ToastBody>
            {action === 'add'
              ? t('Notifications.Contents.View.Synched.Added', {label})
              : t('Notifications.Contents.View.Synched.Removed', {label})}
          </ToastBody>
        </Toast>,
        {timeout: 3000, intent: 'success'},
      );
    } else {
      dispatchToast(
        <Toast>
          <ToastTitle>
            {t('Notifications.Titles.View.Synched.Error')}
          </ToastTitle>
          <ToastBody>
            {t('Notifications.Contents.View.Synched.Error')}
          </ToastBody>
        </Toast>,
        {timeout: 3000, intent: 'error'},
      );
    }
  };

  return (
    <Tooltip
      content={
        isSynched ? t('Main.Card.RemoveSynched') : t('Main.Card.AddSynched')
      }
      relationship="description"
    >
      <Button
        appearance="transparent"
        size="small"
        onClick={isSynched ? removeSynchedContact : addSynchedContact}
        icon={
          isSynched ? (
            <CloudCheckmarkFilled className={classes.iconColor} />
          ) : (
            <CloudRegular className={classes.iconColor} />
          )
        }
      />
    </Tooltip>
  );
};
