import {
  EuiButton,
  EuiButtonEmpty,
  EuiFieldText,
  EuiForm,
  EuiFormRow,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from '@elastic/eui';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';

interface EditTagModalProps {
  isVisible: boolean;
  tagName: string;
  isSaving: boolean;
  onClose: () => void;
  onSave: () => Promise<void>;
  onChange: (value: string) => void;
}

const EditTagModal: FC<EditTagModalProps> = ({
  isVisible,
  tagName,
  isSaving,
  onClose,
  onSave,
  onChange,
}) => {
  const {t} = useTranslation();

  if (!isVisible) return null;

  return (
    <EuiModal onClose={onClose} initialFocus="[name=editTagName]">
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          {t('Configuration.TagsManager.EditModal.Title')}
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>
        <EuiForm>
          <EuiFormRow
            label={t('Configuration.TagsManager.EditModal.Message')}
            fullWidth
          >
            <EuiFieldText
              name="editTagName"
              value={tagName}
              onChange={(e) => onChange(e.target.value)}
              disabled={isSaving}
            />
          </EuiFormRow>
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose} isDisabled={isSaving}>
          {t('Configuration.TagsManager.EditModal.Cancel')}
        </EuiButtonEmpty>
        <EuiButton onClick={onSave} fill isLoading={isSaving}>
          {t('Configuration.TagsManager.EditModal.Save')}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default EditTagModal;
