import {
  EuiButton,
  EuiButtonEmpty,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
} from '@elastic/eui';
import React from 'react';
import {useTranslation} from 'react-i18next';

type DeleteViewModalProps = {
  onClose: () => void;
  onDelete: () => void;
};

const DeleteViewModal: React.FC<DeleteViewModalProps> = ({
  onClose,
  onDelete,
}) => {
  const {t} = useTranslation();

  return (
    <EuiModal onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          {t('Modals.DeleteView.Title')}
        </EuiModalHeaderTitle>
      </EuiModalHeader>
      <EuiModalBody>{t('Modals.DeleteView.Text')}</EuiModalBody>
      <EuiModalFooter>
        <EuiButtonEmpty onClick={onClose}>
          {t('Modals.DeleteView.Cancel')}
        </EuiButtonEmpty>
        <EuiButton color="danger" onClick={onDelete}>
          {t('Modals.DeleteView.Delete')}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default DeleteViewModal;
