import {Attendant} from './components/Attendant';
import {Welcome} from './components/Welcome';
import WrappedSearch from './components/wrappedSearch';
import {Settings} from './pages/settings/Settings';

const routes = [
  {path: '/', exact: true, name: 'Welcome', element: Welcome},
  {path: '/contacts', name: 'Contacts', element: WrappedSearch},
  {path: '/attendant', name: 'Attendant', element: Attendant},
  {path: '/settings', name: 'Settings', element: Settings},
];

export default routes;
