import {
  EuiButton,
  EuiFlexGroup,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui';
import {Guid} from 'guid-typescript';
import {FC, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {EasyTag} from '../../common/interfaces';
import {EasyContext} from '../../components/EasyContext';
import AddTagModal from '../../components/modals/AddTagModal';
import TagManagementTable from '../../components/tables/TagManagementTable';
import {ConfigurationService} from '../../services/ConfigurationService';

export const TagManagerSection: FC = () => {
  const {t} = useTranslation();
  const {accessToken, userId, tenantConfig, dispatch} = useContext(EasyContext);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tag, setTag] = useState('');

  const configurationService = new ConfigurationService(accessToken);

  const handleTagEdit = async (tag: EasyTag, newLabel: string) => {
    if (tenantConfig?.orgTags) {
      const updatedTags = tenantConfig.orgTags.map((t) =>
        t.id === tag.id ? {...tag, label: newLabel} : t,
      );

      const result = await configurationService.RenameTag(
        tag,
        {...tag, label: newLabel},
        tenantConfig.TenantId,
      );
      if (result) {
        dispatch({type: 'UPDATE_TAGS', payload: updatedTags});
      }
    }
  };

  const handleTagDelete = async (tag: EasyTag) => {
    if (tenantConfig?.orgTags) {
      const updatedTags = tenantConfig.orgTags.filter((t) => t.id !== tag.id);
      const result = await configurationService.DeleteTag(
        tag,
        tenantConfig.TenantId,
      );
      if (result) {
        dispatch({type: 'UPDATE_TAGS', payload: updatedTags});
      }
    }
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setTag('');
  };

  const handleAdd = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const newTag: EasyTag = {
        id: Guid.create().toString(),
        label: tag,
      };

      const updatedTags = tenantConfig?.orgTags ?? [];
      updatedTags.push(newTag);

      const result = await configurationService.patchOrgTags(
        updatedTags,
        tenantConfig!.TenantId,
      );

      if (result) {
        dispatch({type: 'UPDATE_TAGS', payload: updatedTags});
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setTag(value);
  };

  if (
    !tenantConfig?.roles?.some(
      (role) => role.roleName === 'TagsManager' && role.users.includes(userId),
    )
  ) {
    return null;
  }

  return (
    <EuiPanel paddingSize="l">
      <EuiTitle size="s">
        <h2>{t('Configuration.TagsManager.Title')}</h2>
      </EuiTitle>
      <EuiSpacer size="l" />
      <EuiText>
        <p>{t('Configuration.TagsManager.Text')}</p>
      </EuiText>
      <EuiSpacer size="l" />
      <EuiFlexGroup gutterSize="s" alignItems="flexStart" wrap>
        <EuiButton
          iconType="plusInCircle"
          size="s"
          color="primary"
          onClick={() => setIsModalVisible(true)}
          fill
        >
          {t('Configuration.TagsManager.AddButton')}
        </EuiButton>
      </EuiFlexGroup>
      <EuiSpacer size="l" />
      {tenantConfig?.orgTags && tenantConfig.orgTags.length > 0 ? (
        <TagManagementTable
          tags={tenantConfig.orgTags}
          onDeleteTag={handleTagDelete}
          onEditTag={handleTagEdit}
        />
      ) : (
        <EuiText>
          <p>{t('Configuration.TagsManager.NoTags')}</p>
        </EuiText>
      )}

      {isModalVisible && (
        <AddTagModal
          isVisible={isModalVisible}
          isLoading={isLoading}
          tag={tag}
          onClose={closeModal}
          onSubmit={handleAdd}
          onChange={handleTagChange}
        />
      )}
    </EuiPanel>
  );
};
