import axios from 'axios';

import config from '../common/config';
import {
  EasyTag,
  PersonalView,
  SharedMailbox,
  UserConfiguration,
} from '../common/interfaces';

export class ConfigurationService {
  constructor(access_token?: string) {
    if (access_token !== undefined && access_token !== null) {
      if (access_token.startsWith('Bearer ')) {
        this.access_token = access_token;
      } else {
        this.access_token = 'Bearer ' + access_token;
      }
    }
  }

  access_token: string | undefined;

  async getUserConfiguration(
    userId: string,
  ): Promise<UserConfiguration | null> {
    try {
      const response = await axios.get(
        config.apiEndpoint + '/easydirectory/configurations/' + userId,
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data.Documents[0];
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async postUserConfiguration(
    userId: string,
  ): Promise<UserConfiguration | null> {
    try {
      const response = await axios.post(
        config.apiEndpoint + '/easydirectory/configurations/' + userId,
        {},
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async updateDefaultView(
    userId: string,
    viewId: string,
  ): Promise<UserConfiguration | null> {
    try {
      const response = await axios.patch(
        config.apiEndpoint +
          '/easydirectory/configurations/' +
          userId +
          '/default/' +
          viewId,
        {},
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async postSharedMailbox(
    userId: string,
    mailbox: SharedMailbox,
  ): Promise<UserConfiguration | null> {
    try {
      const response = await axios.post(
        config.apiEndpoint +
          '/easydirectory/configurations/' +
          userId +
          '/sharedMailboxes',
        mailbox,
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async deleteSharedMailbox(
    userId: string,
    mailboxId: string,
  ): Promise<UserConfiguration | null> {
    try {
      const response = await axios.delete(
        config.apiEndpoint +
          '/easydirectory/configurations/' +
          userId +
          '/sharedMailboxes/' +
          mailboxId,
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async updatePersonalView(
    userId: string,
    updatedView: PersonalView,
  ): Promise<UserConfiguration | null> {
    try {
      const response = await axios.put(
        config.apiEndpoint +
          '/easydirectory/configurations/' +
          userId +
          '/personalView',
        updatedView,
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async postPersonalView(
    userId: string,
    view: PersonalView,
  ): Promise<UserConfiguration | null> {
    try {
      const response = await axios.post(
        config.apiEndpoint +
          '/easydirectory/configurations/' +
          userId +
          '/personalViews',
        view,
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async deletePersonalView(
    userId: string,
    viewId: string,
  ): Promise<UserConfiguration | null> {
    try {
      const response = await axios.delete(
        config.apiEndpoint +
          '/easydirectory/configurations/' +
          userId +
          '/personalViews/' +
          viewId,
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async postFavorite(
    userId: string,
    favoriteId: string,
  ): Promise<UserConfiguration | null> {
    try {
      const response = await axios.post(
        config.apiEndpoint +
          '/easydirectory/configurations/' +
          userId +
          '/favorites',
        favoriteId,
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async deleteFavorite(
    userId: string,
    favoriteId: number,
  ): Promise<UserConfiguration | null> {
    try {
      const response = await axios.delete(
        config.apiEndpoint +
          '/easydirectory/configurations/' +
          userId +
          '/favorites/' +
          favoriteId,
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async postSynchedUser(
    userId: string,
    synchedContact: any,
  ): Promise<UserConfiguration | null> {
    try {
      const response = await axios.post(
        config.apiEndpoint +
          '/easydirectory/configurations/' +
          userId +
          '/synchedContacts',
        synchedContact,
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async getTenantConfiguration(): Promise<UserConfiguration | null> {
    try {
      const response = await axios.get(
        config.apiEndpoint + '/easydirectory/admin/configuration/',
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data.Documents[0];
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async deleteSynchedUser(
    userId: string,
    synchedContactId: string,
  ): Promise<UserConfiguration | null> {
    try {
      const response = await axios.delete(
        config.apiEndpoint +
          '/easydirectory/configurations/' +
          userId +
          '/synchedContacts/' +
          synchedContactId,
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  async getTagManagingPermission(userId: string): Promise<boolean> {
    try {
      const response = await axios.get<{result: boolean}>(
        config.apiEndpoint + '/easydirectory/permission/' + userId + '/tags/',
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data.result;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async DeleteTag(tag: EasyTag, TenantId: string): Promise<boolean> {
    try {
      const response = await axios.post(
        config.apiEndpoint + '/easydirectory/tags/delete',
        {
          TenantId: TenantId,
          TagToDelete: tag,
        },
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.status === 200;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async RenameTag(
    oldTag: EasyTag,
    newTag: EasyTag,
    tenantId: string,
  ): Promise<boolean> {
    try {
      const response = await axios.post(
        config.apiEndpoint + '/easydirectory/tags/rename',
        {TenantId: tenantId, OldTag: oldTag, NewTag: newTag},
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.status === 200;
    } catch (e) {
      console.log(e);
      return false;
    }
  }

  async patchOrgTags(tags: EasyTag[], tenantId: string): Promise<boolean> {
    try {
      const response = await axios.patch(
        config.apiEndpoint + '/easydirectory/admin/' + tenantId + '/orgTags',
        tags,
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.status === 200;
    } catch (e) {
      console.log(e);
      return false;
    }
  }
}
