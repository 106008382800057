// ToastContext.tsx

import {
  FluentProvider,
  teamsLightTheme,
  Toaster,
  useToastController,
} from '@fluentui/react-components';
import React, {createContext, ReactNode, useContext} from 'react';

const ToastContext = createContext<{dispatchToast: any; toasterId: string}>({
  dispatchToast: () => {},
  toasterId: '',
});

interface ToastProviderProps {
  children: ReactNode;
}

const TOASTER_ID = 'toaster';

export const ToastProvider: React.FC<ToastProviderProps> = ({children}) => {
  const {dispatchToast} = useToastController(TOASTER_ID);

  return (
    <ToastContext.Provider value={{dispatchToast, toasterId: TOASTER_ID}}>
      {children}
      <FluentProvider theme={teamsLightTheme}>
        <Toaster toasterId={TOASTER_ID} />
      </FluentProvider>
    </ToastContext.Provider>
  );
};

export const useToast = () => useContext(ToastContext);
