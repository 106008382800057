import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import '../../components/style.scss';
import '@elastic/eui/dist/eui_theme_light.css';

import {
  ErrorBoundary,
  Paging,
  PagingInfo,
  Results,
  ResultsPerPage,
  SearchProvider,
  WithSearch,
} from '@elastic/react-search-ui';
import {Layout} from '@elastic/react-search-ui-views';

import {UserConfiguration} from '../../common/interfaces';

import '@elastic/react-search-ui-views/lib/styles/styles.css';

import {
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui';
import AppSearchAPIConnector from '@elastic/search-ui-app-search-connector';
import {Spinner, Theme, webLightTheme} from '@fluentui/react-components';
import {Trans, useTranslation} from 'react-i18next';

import {TeamsUserCredentialContext} from '../../auth/singletonContext';
import config from '../../common/config';
import {EasyContext} from '../../components/EasyContext';
import {EasyDirectoryResultView} from '../../components/EasyDirectoryResult';
import {OptionsMenu} from '../../components/OptionsMenu';
import EasyBodyContentView from '../../components/views/EasyBodyContentView';
import EasySearchHeader from '../../components/views/EasySearchHeader';
import {EasySideContentView} from '../../components/views/EasySideContentView';
import {useEasyPageOptionsProvider} from '../../contexts/EasyPageOptionsContext';
import {useEasySearchProvider} from '../../contexts/EasySearchContext';
import {ConfigurationService} from '../../services/ConfigurationService';
import {GroupedResultView} from './GroupedResultView';

export const customLightTheme: Theme = {
  ...webLightTheme,
  colorNeutralBackground1: 'transparent',
};

export const Search: React.FC = memo(() => {
  const easyContext = useContext(EasyContext);
  const credential = TeamsUserCredentialContext.getInstance().getCredential();
  const {selectedView, setSelectedView} = useEasySearchProvider();
  const {pageOptions} = useEasyPageOptionsProvider();

  const {t} = useTranslation();

  const [userConfig, setUserConfig] = useState<UserConfiguration>();
  const [hasInitialized, setHasInitialized] = useState(false);

  const [specialViewState, setSpecialViewState] = useState<{
    isSpecialView: boolean;
    noItemsAvailable: boolean;
    viewType: 'favorites' | 'synched' | null;
  }>({
    isSpecialView: false,
    noItemsAvailable: false,
    viewType: null,
  });

  const configurationService = useMemo(
    () => new ConfigurationService(easyContext.accessToken),
    [easyContext.accessToken],
  );

  if (!credential) {
    throw new Error('TeamsFx SDK is not initialized.');
  }

  useEffect(() => {
    if (
      !hasInitialized &&
      !selectedView &&
      easyContext.userConfig?.DefaultView
    ) {
      const view = easyContext.userConfig.Views.find(
        (view) => view.id === easyContext.userConfig?.DefaultView,
      );
      if (view) {
        setSelectedView(view);
      } else if (easyContext.tenantConfig?.orgViews) {
        const orgView = easyContext.tenantConfig.orgViews.find(
          (view) => view.id === easyContext.userConfig?.DefaultView,
        );
        if (orgView) {
          setSelectedView(orgView);
        }
      }
      setHasInitialized(true);
    }
  }, [
    easyContext.userConfig,
    easyContext.tenantConfig,
    selectedView,
    hasInitialized,
    setSelectedView,
  ]);

  useEffect(() => {
    if (selectedView?.id === 'favourites_view') {
      const hasFavorites = Boolean(
        easyContext.userConfig?.Favorites &&
          easyContext.userConfig.Favorites.length > 0,
      );
      setSpecialViewState({
        isSpecialView: true,
        noItemsAvailable: !hasFavorites,
        viewType: 'favorites',
      });
    } else if (selectedView?.id === 'synched_contacts_view') {
      const hasSynchedContacts = Boolean(
        easyContext.userConfig?.SynchedContacts &&
          easyContext.userConfig.SynchedContacts.length > 0,
      );
      setSpecialViewState({
        isSpecialView: true,
        noItemsAvailable: !hasSynchedContacts,
        viewType: 'synched',
      });
    } else {
      setSpecialViewState({
        isSpecialView: false,
        noItemsAvailable: false,
        viewType: null,
      });
    }
  }, [
    selectedView,
    easyContext.userConfig?.Favorites,
    easyContext.userConfig?.SynchedContacts,
  ]);

  const getEmptyStateMessage = () => {
    if (specialViewState.viewType === 'favorites') {
      return {
        title: t('Notifications.Titles.View.Favorites.NoFavorites'),
        description: t('Notifications.Contents.View.Favorites.NoFavorites'),
      };
    } else if (specialViewState.viewType === 'synched') {
      return {
        title: t('Notifications.Titles.View.SynchedContacts.NoContacts'),
        description: t(
          'Notifications.Contents.View.SynchedContacts.NoContacts',
        ),
      };
    }
    return {title: '', description: ''};
  };

  const loadUserConfig = useCallback(() => {
    configurationService
      .getUserConfiguration(easyContext.userId)
      .then((config: UserConfiguration | null) => {
        if (config) {
          setUserConfig(config);
        }
      });
  }, [configurationService, easyContext.userId]);

  useEffect(() => {
    loadUserConfig();
  }, [loadUserConfig]);

  const searchConfig = useMemo(() => {
    const connector = new AppSearchAPIConnector({
      searchKey: easyContext.accessToken,
      engineName: easyContext.tenantId,
      endpointBase: config.apiEndpoint + '/easydirectory/search',
    });

    const elasticSearchConfig = {
      debug: false,
      apiConnector: connector,
      alwaysSearchOnInitialLoad: false,
      hasA11yNotifications: false,
      searchQuery: {
        ...(pageOptions.groupedBy?.value && {
          group: {
            field: pageOptions.groupedBy.value,
          },
        }),
        result_fields: {
          id: {raw: {}},
          type: {raw: {}},
          display_name: {raw: {}},
          given_name: {raw: {}},
          surname: {raw: {}},
          mail: {raw: {}},
          mobile_phone: {raw: {}},
          business_phones: {raw: {}},
          job_title: {raw: {}},
          office_location: {raw: {}},
          upn: {raw: {}},
          city: {raw: {}},
          company_name: {raw: {}},
          country: {raw: {}},
          department: {raw: {}},
          fax_number: {raw: {}},
          postal_code: {raw: {}},
          street_address: {raw: {}},
          tags: {raw: {}},
        },
        disjunctiveFacets: [
          'country',
          'city',
          'company_name',
          'department',
          'office_location',
          'postal_code',
          'type',
          'tags',
          'job_title',
        ],
        facets: {
          country: {type: 'value', size: 30},
          city: {type: 'value', size: 30},
          company_name: {type: 'value', size: 30},
          department: {type: 'value', size: 30},
          office_location: {type: 'value', size: 30},
          postal_code: {type: 'value', size: 30},
          type: {type: 'value', size: 30},
          tags: {type: 'value', size: 30},
          job_title: {type: 'value', size: 30},
        },
      },
      autocompleteQuery: {
        results: {
          resultsPerPage: 20,
          result_fields: {
            display_name: {
              snippet: {
                size: 100,
                fallback: true,
              },
            },
          },
        },
        suggestions: {
          types: {
            documents: {
              fields: ['display_name', 'company_name', 'tags'],
            },
          },
          size: 4,
        },
      },
    };

    return elasticSearchConfig;
  }, [pageOptions, easyContext.accessToken, easyContext.tenantId]);

  const CustomPagingInfoView = (props: any) => {
    const {ariaLabel, className, start, end, totalResults} = props;
    const range = `${start} - ${end}`;
    const total = totalResults;

    return (
      <div aria-label={ariaLabel} className={className}>
        <Trans i18nKey="Main.Pagination.Showing" values={{range, total}}>
          <b>{range}</b> of <b>{total}</b>
        </Trans>
      </div>
    );
  };

  return (
    <SearchProvider key="search" config={searchConfig}>
      <WithSearch
        mapContextToProps={({
          wasSearched,
          results,
          setSearchTerm,
          resultSearchTerm,
          isLoading,
        }) => ({
          wasSearched,
          results,
          setSearchTerm,
          resultSearchTerm,
          isLoading,
        })}
      >
        {({
          wasSearched,
          results,
          setSearchTerm,
          resultSearchTerm,
          isLoading,
        }) => {
          const emptyStateMessage = getEmptyStateMessage();

          return (
            <div className="App">
              <ErrorBoundary>
                <Layout
                  header={<EasySearchHeader />}
                  sideContent={
                    !specialViewState.noItemsAvailable ? (
                      <EasySideContentView />
                    ) : null
                  }
                  bodyContent={
                    <>
                      {isLoading ? (
                        <Spinner />
                      ) : specialViewState.noItemsAvailable ? (
                        <EuiFlexGroup justifyContent="spaceAround">
                          <EuiFlexItem grow={false}>
                            <EuiSpacer size="xxl" />
                            <EuiPanel paddingSize="l">
                              <EuiTitle size="s">
                                <h2>{emptyStateMessage.title}</h2>
                              </EuiTitle>
                              <EuiSpacer size="m" />
                              <EuiText>
                                <p>{emptyStateMessage.description}</p>
                              </EuiText>
                            </EuiPanel>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      ) : !wasSearched ? (
                        <EuiFlexGroup justifyContent="spaceAround">
                          <EuiFlexItem grow={false}>
                            <EuiSpacer size="xxl" />
                            <EuiPanel paddingSize="l">
                              <EuiTitle size="s">
                                <h2>{t('Main.Search.Initial.Title')}</h2>
                              </EuiTitle>
                              <EuiSpacer size="m" />
                              <EuiText>
                                <p>{t('Main.Search.Initial.Description')}</p>
                              </EuiText>
                            </EuiPanel>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      ) : (
                        <>
                          <EasyBodyContentView>
                            {pageOptions.groupedBy?.value ? (
                              <GroupedResultView
                                results={results}
                                config={userConfig!}
                                setSearchTerm={setSearchTerm}
                                searchTerm={resultSearchTerm}
                              />
                            ) : (
                              <Results
                                resultView={(result) =>
                                  EasyDirectoryResultView({
                                    resultView: result,
                                    config: userConfig!,
                                  })
                                }
                                titleField="display_name"
                                urlField="display_name"
                                thumbnailField="display_name"
                                shouldTrackClickThrough={true}
                              />
                            )}
                          </EasyBodyContentView>
                        </>
                      )}
                    </>
                  }
                  bodyHeader={
                    !specialViewState.noItemsAvailable ? (
                      <>
                        {wasSearched && (
                          <>
                            {t('Main.Pagination.Show')}
                            <ResultsPerPage className="easy-select" />
                          </>
                        )}
                        {wasSearched && (
                          <PagingInfo view={CustomPagingInfoView} />
                        )}
                        <EuiFlexGroup justifyContent="flexEnd">
                          <EuiFlexItem grow={false}>
                            <OptionsMenu />
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </>
                    ) : null
                  }
                  bodyFooter={
                    !specialViewState.noItemsAvailable ? (
                      <>
                        <Paging />
                      </>
                    ) : null
                  }
                />
              </ErrorBoundary>
            </div>
          );
        }}
      </WithSearch>
    </SearchProvider>
  );
});
