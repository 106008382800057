import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiSpacer,
  EuiTitle,
} from '@elastic/eui';
import {ResultViewProps} from '@elastic/react-search-ui-views';
import {Text} from '@fluentui/react-components';
import {memo, useState} from 'react';

import {DeepLinkActionType} from '../../common/enums';
import {StringToColor} from '../../common/helpers';
import {deepLinkHandler} from '../../handlers/deepLinkHandler';
import {EasyCardLine} from '../cards/EasyCardLine';
import {DetailsModal} from '../DetailsModal';
import {EasyUser} from '../EasyUser';
import {FavoriteStar} from '../FavoriteStar';
import {QuickActions} from '../QuickActions';
import {SynchedContact} from '../SynchedContact';

interface ResultHeaderProps {
  resultView: ResultViewProps;
  onOpenDetailModal: () => void;
}

const ResultHeader = memo(
  ({resultView, onOpenDetailModal}: ResultHeaderProps) => (
    <div className="sui-result__header">
      <EuiFlexGroup alignItems="flexStart">
        <EuiFlexItem grow={false}>
          <EasyUser
            userId={resultView.result.id?.raw}
            displayName={resultView.result.display_name?.raw}
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiTitle size="xs">
            <h1>{resultView.result.display_name?.raw}</h1>
          </EuiTitle>
          <QuickActions
            resultView={resultView}
            handleDetailModalOpen={onOpenDetailModal}
          />
        </EuiFlexItem>
        <EuiFlexItem grow={false}>
          <EuiFlexGroup direction="column" gutterSize="s">
            <EuiFlexItem>
              <FavoriteStar contactId={resultView.result.id?.raw} />
            </EuiFlexItem>
            <EuiFlexItem>
              {resultView.result.type?.raw !== 'entraid' && (
                <SynchedContact
                  contactId={resultView.result.id?.raw}
                  displayName={resultView.result.display_name?.raw}
                />
              )}
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  ),
);

ResultHeader.displayName = 'ResultHeader';

const ResultDetails: React.FC<{resultView: ResultViewProps}> = ({
  resultView,
}) => (
  <div className="sui-result__body">
    <EuiFlexGroup direction="column" gutterSize="s">
      <EuiFlexItem>
        <Text weight="bold">Details:</Text>
      </EuiFlexItem>
      {resultView.result.mail?.raw && (
        <EuiFlexItem>
          <EasyCardLine
            iconType="email"
            copyEnabled
            onCopy={() =>
              navigator.clipboard.writeText(resultView.result.mail.raw)
            }
            clickEnabled
            onClick={() =>
              deepLinkHandler(
                resultView.result.mail.raw,
                DeepLinkActionType.Email,
              )
            }
          >
            {resultView.result.mail.raw}
          </EasyCardLine>
        </EuiFlexItem>
      )}
      {resultView.result.business_phones?.raw && (
        <EuiFlexItem>
          <EasyCardLine
            iconType="phone"
            copyEnabled
            onCopy={() =>
              navigator.clipboard.writeText(
                resultView.result.business_phones.raw,
              )
            }
            clickEnabled
            onClick={() =>
              deepLinkHandler(
                resultView.result.business_phones.raw,
                DeepLinkActionType.PSTNCall,
              )
            }
          >
            {resultView.result.business_phones.raw}
          </EasyCardLine>
        </EuiFlexItem>
      )}
      {resultView.result.mobile_phone?.raw && (
        <EuiFlexItem>
          <EasyCardLine
            iconType="mobile"
            copyEnabled
            onCopy={() =>
              navigator.clipboard.writeText(resultView.result.mobile_phone.raw)
            }
            clickEnabled
            onClick={() =>
              deepLinkHandler(
                resultView.result.mobile_phone.raw,
                DeepLinkActionType.PSTNCall,
              )
            }
          >
            {resultView.result.mobile_phone.raw}
          </EasyCardLine>
        </EuiFlexItem>
      )}
      {resultView.result.street_address?.raw && resultView.result.city?.raw && (
        <EuiFlexItem>
          <EasyCardLine
            iconType="map"
            clickEnabled
            onClick={() =>
              deepLinkHandler(
                `${resultView.result.street_address.raw}, ${resultView.result.city.raw}`,
                DeepLinkActionType.GoogleMaps,
              )
            }
          >
            <span>{`${resultView.result.street_address.raw}, ${resultView.result.city.raw}`}</span>
          </EasyCardLine>
        </EuiFlexItem>
      )}
      {resultView.result.tags?.raw && (
        <>
          <EuiSpacer size="s" />
          <EuiFlexItem>
            <EuiFlexGroup wrap gutterSize="xs">
              {resultView.result.tags.raw.map((tag: string, index: number) => (
                <EuiFlexItem key={index} grow={false}>
                  <EuiBadge>{tag}</EuiBadge>
                </EuiFlexItem>
              ))}
            </EuiFlexGroup>
          </EuiFlexItem>
        </>
      )}
    </EuiFlexGroup>
  </div>
);

interface EasyStandardViewProps {
  resultView: ResultViewProps;
}

export const EasyStandardView: React.FC<EasyStandardViewProps> = ({
  resultView,
}) => {
  const [isDetailModalVisible, setIsDetailModalVisible] = useState(false);

  const handleDetailModalClose = () => setIsDetailModalVisible(false);
  const handleDetailModalOpen = () => setIsDetailModalVisible(true);

  return (
    <>
      {resultView.result && (
        <li
          className={`sui-result-standard ${resultView.result.type?.raw} standardView`}
          style={{
            width: '100%',
            borderLeft: `7px solid ${
              resultView.result.type?.raw === 'entraid'
                ? '#6264A7'
                : StringToColor(resultView.result.type?.raw)
            }`,
          }}
          key={resultView.result.id?.raw}
        >
          <ResultHeader
            resultView={resultView}
            onOpenDetailModal={handleDetailModalOpen}
          />
          <EuiSpacer size="m" />
          <ResultDetails resultView={resultView} />
          <DetailsModal
            resultView={resultView}
            isOpen={isDetailModalVisible}
            onClose={handleDetailModalClose}
          />
        </li>
      )}
    </>
  );
};
