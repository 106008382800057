import {
  DropResult,
  EuiAccordion,
  EuiButton,
  EuiButtonIcon,
  EuiContextMenuItem,
  EuiContextMenuPanel,
  EuiDragDropContext,
  EuiDraggable,
  EuiDroppable,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiPopover,
  EuiSpacer,
  EuiTitle,
} from '@elastic/eui';
import {useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {EasyDirectoryUser, UserConfiguration} from '../common/interfaces';
import {ConfigurationService} from '../services/ConfigurationService';
import {SearchService} from '../services/SearchService';
import {SignalRService} from '../services/SignalRService';
import {EasyContext} from './EasyContext';
import {EasyFavoriteView} from './views/EasyFavoritesView';

export function Attendant() {
  const {t} = useTranslation();
  const [userConfig, setUserConfig] = useState<UserConfiguration>();
  const [favorites, setFavorites] = useState<EasyDirectoryUser[]>([]);

  const [, setSignalrStatus] = useState('Connecting');
  // const [calls, setCalls] = useState<string[]>([]);
  // const [users, setUsers] = useState<string[]>([]);

  const easyContext = useContext(EasyContext);

  const signalRService = useMemo(
    () =>
      new SignalRService(
        'https://fct-easycallreport-signalr-dev.azurewebsites.net/api',
        '',
      ),
    [],
  );

  const configurationService = useMemo(
    () => new ConfigurationService(easyContext.accessToken),
    [easyContext.accessToken],
  );

  const searchService = useMemo(
    () => new SearchService(easyContext.accessToken),
    [easyContext.accessToken],
  );

  const loadFavorites = useCallback(
    (config: UserConfiguration) => {
      if (config?.Favorites) {
        searchService
          .getFavorites(config?.Favorites)
          .then((favorites: EasyDirectoryUser[] | null) => {
            if (favorites) {
              setFavorites(favorites);
            }
          });
      }
    },
    [searchService],
  );

  const loadUserConfig = useCallback(() => {
    configurationService
      .getUserConfiguration(easyContext.userId)
      .then((config: UserConfiguration | null) => {
        if (config) {
          setUserConfig(config);
          loadFavorites(config);
        }
      });
  }, [configurationService, easyContext.userId, loadFavorites]);

  useEffect(() => {
    loadUserConfig();
    //signalRService.startConnection();

    setSignalrStatus('Connected');

    //   signalRService.onReceiveMessage((receivedCall: ICall) => {
    //     if (receivedCall.State === null && receivedCall.State === undefined) {
    //       return;
    //     }

    //     if (receivedCall.State === 0) {
    //       setCalls([...calls, receivedCall]);
    //     } else {
    //       removeCall(receivedCall);
    //     }
    //   });
    // }

    return () => {
      signalRService.stopConnection();
      setSignalrStatus('Disconnected');
    };
  }, [loadUserConfig, signalRService]);

  // const actions = {
  //   DROPPABLE_AREA_COPY_1: setUsers,
  //   DROPPABLE_AREA_COPY_2: setCalls,
  // };

  // const onDragUpdate = (props: DragDropContextProps) => {
  //   const shouldRemove =
  //       !props..destination && props.source.droppableId === 'DROPPABLE_AREA_COPY_2';
  //   setIsItemRemovable(shouldRemove);
  // };

  // const makeId = htmlIdGenerator();

  const onDragEnd = (props: DropResult) => {
    if (props.source && props.destination) {
      if (props.source.droppableId === props.destination.droppableId) {
        // const items = euiDragDropReorder(
        //     lists[props.destination.droppableId],
        //     props.source.index,
        //     props.destination.index
        // );
        // actions[props.destination.droppableId](items);
      } else {
        // const sourceId = props.source.droppableId;
        // const destinationId = props.destination.droppableId;
        // const result = euiDragDropCopy(
        //     lists[sourceId],
        //     lists[destinationId],
        //     props.source,
        //     props.destination,
        //     {
        //         property: 'id',
        //         modifier: makeId,
        //     }
        // );
        // actions[sourceId](result[sourceId]);
        // actions[destinationId](result[destinationId]);
      }
      // } else if (!destination && source.droppableId === 'DROPPABLE_AREA_COPY_2') {
      //     // remove(source.droppableId, source.index);
      // }
    }
  };

  const [isPopoverOpen, setPopover] = useState(false);
  const closePopover = () => {
    setPopover(false);
  };

  const onCallContectButtonClick = () => {
    setPopover(!isPopoverOpen);
  };

  const callContextItems = [
    <EuiContextMenuItem key="edit" icon="user" onClick={closePopover}>
      {t('AttendantMode.Transfer')}
    </EuiContextMenuItem>,
    <EuiContextMenuItem key="share" icon="storage" onClick={closePopover}>
      {t('AttendantMode.SendVoiceMail')}
    </EuiContextMenuItem>,
    <EuiContextMenuItem key="share" icon="bellSlash" onClick={closePopover}>
      {t('AttendantMode.Decline')}
    </EuiContextMenuItem>,
  ];

  return (
    <div className="tcf-configuration">
      <EuiDragDropContext onDragEnd={onDragEnd}>
        <EuiTitle>
          <h2>
            {' '}
            {t('AttendantMode.Greetings', {
              name: easyContext.userDisplayName,
            })}{' '}
          </h2>
        </EuiTitle>
        <EuiSpacer size="xxl" />
        <EuiPanel paddingSize="l">
          <EuiTitle size="xs">
            <h2>{t('AttendantMode.IncomingCalls')}</h2>
          </EuiTitle>
          <EuiSpacer size="m" />

          <EuiDroppable
            droppableId="DROPPABLE_AREA_COPY_1"
            cloneDraggables={true}
            spacing="l"
            grow
          >
            <EuiDraggable spacing="m" key={1} index={1} draggableId={'1'}>
              <EuiAccordion
                id="callId"
                buttonContent="Steffen Kabus (+41 76 215 97 81)"
                extraAction={
                  <EuiFlexGroup
                    responsive={false}
                    gutterSize="xs"
                    alignItems="center"
                  >
                    <EuiFlexItem grow={false}>
                      <EuiButton size="s" iconType="check">
                        {t('AttendantMode.AcceptCall')}
                      </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiPopover
                        id="splitButtonPopoverId2"
                        button={
                          <EuiButtonIcon
                            display="base"
                            size="s"
                            iconType="boxesVertical"
                            aria-label="More"
                            onClick={onCallContectButtonClick}
                          />
                        }
                        isOpen={isPopoverOpen}
                        closePopover={closePopover}
                        panelPaddingSize="none"
                        anchorPosition="downLeft"
                      >
                        <EuiContextMenuPanel
                          size="s"
                          items={callContextItems}
                        />
                      </EuiPopover>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                }
                paddingSize="l"
              >
                <strong>Opened content.</strong>
              </EuiAccordion>
            </EuiDraggable>
            <EuiSpacer size="s" />
            {/* <EuiDraggable spacing="m" key={2} index={2} draggableId={"2"}>
                            <EuiAccordion
                                id="callId"
                                buttonContent="Christoph Schoch (+41 44 666 50 50)"
                                extraAction={
                                <EuiFlexGroup responsive={false} gutterSize="xs" alignItems="center">
                                    <EuiFlexItem grow={false}>
                                        <EuiButton size="s" iconType="calendar">
                                            Accept Call
                                        </EuiButton>
                                    </EuiFlexItem>
                                    <EuiFlexItem grow={false}>
                                        <EuiPopover
                                            id="splitButtonPopoverId1"
                                            button={
                                                <EuiButtonIcon
                                                    display="base"
                                                    size="s"
                                                    iconType="boxesVertical"
                                                    aria-label="More"
                                                    onClick={onCallContectButtonClick}
                                                />
                                            }
                                            isOpen={isPopoverOpen}
                                            closePopover={closePopover}
                                            panelPaddingSize="none"
                                            anchorPosition="downLeft"
                                        >
                                            <EuiContextMenuPanel size="s" items={callContextItems} />
                                        </EuiPopover>
                                    </EuiFlexItem>
                                </EuiFlexGroup>}
                                paddingSize="l"
                            >
                                <strong>Opened content.</strong>
                            </EuiAccordion>
                        </EuiDraggable> */}
          </EuiDroppable>
        </EuiPanel>
        <EuiSpacer size="xxl" />
        <EuiPanel paddingSize="l" grow={false}>
          <EuiTitle size="xs">
            <h2>{t('AttendantMode.FavoriteUsers')}</h2>
          </EuiTitle>
          <EuiSpacer size="m" />
          <EuiFlexGroup wrap>
            {favorites.map((favorite: EasyDirectoryUser) => {
              return (
                <EuiFlexItem grow={false}>
                  <EasyFavoriteView
                    user={favorite}
                    config={userConfig!}
                    onFavoritesToggle={() => loadUserConfig()}
                  ></EasyFavoriteView>
                </EuiFlexItem>
              );
            })}
          </EuiFlexGroup>
          <EuiDroppable droppableId="DROPPABLE_AREA_COPY_2" grow>
            {/* {users.length ? (
                            users.map(({ content, id }, idx) => (
                                <EuiDraggable
                                    key={id}
                                    index={idx}
                                    draggableId={id}
                                    spacing="l"
                                    isRemovable={isItemRemovable}
                                >
                                    <EuiPanel>
                                        <EuiFlexGroup gutterSize="none" alignItems="center">
                                            <EuiFlexItem>{content}</EuiFlexItem>
                                            <EuiFlexItem grow={false}>
                                                {isItemRemovable ? (
                                                    <EuiIcon type="trash" color="danger" />
                                                ) : (
                                                    <EuiButtonIcon
                                                        iconType="cross"
                                                        aria-label="Remove"
                                                    // onClick={() => remove('DROPPABLE_AREA_COPY_2', idx)}
                                                    />
                                                )}
                                            </EuiFlexItem>
                                        </EuiFlexGroup>
                                    </EuiPanel>
                                </EuiDraggable>
                            ))
                        ) : ( */}
            <EuiFlexGroup
              alignItems="center"
              justifyContent="spaceAround"
              gutterSize="none"
              style={{height: '100%'}}
            >
              <EuiFlexItem grow={false}></EuiFlexItem>
            </EuiFlexGroup>
            {/* )} */}
          </EuiDroppable>
        </EuiPanel>
      </EuiDragDropContext>
    </div>
  );
}
