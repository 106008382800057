// const config = {
//   teamsUrl: 'https://teams.cloud.microsoft',
//   initiateLoginEndpoint:
//     'https://j86zc4m6.euw.devtunnels.ms:3000/auth-start.html',
//   clientId: '9584c7c0-a816-41fe-9b6b-f9964a14c2af',
//   apiEndpoint: 'https://api.dev.easyplatform.app',
//   graphEndpint: 'https://graph.microsoft.com',
//   adminLoginUrl: 'https://admin.easyplatform.app/',
//   teamsAppUrl: 'https://j86zc4m6.euw.devtunnels.ms:3000/',
//   scopes: ['https://graph.microsoft.com/.default'],
//   userScopes: ['Presence.Read.All', 'User.Read.All'],
// };

const config = {
  teamsUrl: 'https://teams.cloud.microsoft',
  initiateLoginEndpoint: 'https://teams-test.easydirectory.app/auth-start',
  clientId: 'd466c9e8-4915-4be1-879d-008e4a59fe4c',
  apiEndpoint: 'https://api.dev.easyplatform.app',
  graphEndpint: 'https://graph.microsoft.com',
  adminLoginUrl: 'https://admin.dev.easyplatform.app/',
  teamsAppUrl: 'https://teams-test.easydirectory.app',
  scopes: ['https://graph.microsoft.com/.default'],
  userScopes: ['Presence.Read.All', 'User.Read.All'],
};

export default config;
