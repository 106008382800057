import axios from 'axios';

import config from '../common/config';
import {EasyDirectoryUser} from '../common/interfaces';

export class SearchService {
  constructor(access_token?: string) {
    if (access_token !== undefined && access_token !== null) {
      if (access_token.startsWith('Bearer ')) {
        this.access_token = access_token;
      } else {
        this.access_token = 'Bearer ' + access_token;
      }
    }
  }

  access_token: string | undefined;

  async getFavorites(userIds: string[]): Promise<EasyDirectoryUser[] | null> {
    try {
      const queryParams = new URLSearchParams();

      userIds.forEach((userId: string) => {
        queryParams.append('ids[]', userId);
      });

      const response = await axios.get(
        config.apiEndpoint +
          '/easydirectory/contacts?' +
          queryParams.toString(),
        {
          headers: {
            Authorization: this.access_token,
            'Content-Type': 'application/json',
          },
        },
      );

      return response.data;
    } catch (e) {
      console.log(e);
      return null;
    }
  }
}
