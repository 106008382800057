import {EuiButtonEmpty} from '@elastic/eui';
import {withSearch} from '@elastic/react-search-ui';
import {Filter} from '@elastic/search-ui/lib/esm/types';
import {useCallback} from 'react';
import {useTranslation} from 'react-i18next';

const EasySearchReset = (props: {
  setSearchTerm: any;
  clearFilters: any;
  searchTerm: string | undefined;
  filters: Filter[] | undefined;
}) => {
  const handleReset = useCallback(() => {
    props.setSearchTerm('');
    props.clearFilters();
  }, [props]);

  const {t} = useTranslation();

  return (
    <>
      {((props.filters?.length || 0) > 0 || props.searchTerm !== '') && (
        <EuiButtonEmpty onClick={handleReset} iconType="filterExclude">
          {t('Button.Reset')}
        </EuiButtonEmpty>
      )}
    </>
  );
};

export default withSearch(
  ({setSearchTerm, clearFilters, searchTerm, filters}) => ({
    searchTerm,
    setSearchTerm,
    clearFilters,
    filters,
  }),
)(EasySearchReset);
