import {
  EuiButton,
  EuiFlexGroup,
  EuiPanel,
  EuiSpacer,
  EuiText,
  EuiTitle,
} from '@elastic/eui';
import {Guid} from 'guid-typescript';
import {FC, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {EasyContext} from '../../components/EasyContext';
import AddMailboxModal from '../../components/modals/AddMailboxModal';
import SharedMailboxTable from '../../components/tables/SharedMailboxTable';
import {ConfigurationService} from '../../services/ConfigurationService';

export const SharedMailboxSection: FC = () => {
  const {t} = useTranslation();
  const {accessToken, userId, userConfig, dispatch} = useContext(EasyContext);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showErrors, setShowErrors] = useState(false);
  const [mailboxAddress, setMailboxAddress] = useState('');
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const configurationService = new ConfigurationService(accessToken);

  const validateEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMailboxAddress(value);
    setIsValidEmail(validateEmail(value));
    setShowErrors(false);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setShowErrors(false);
    setMailboxAddress('');
    setIsValidEmail(false);
  };

  const handleDelete = async (mailboxId: string) => {
    if (mailboxId) {
      try {
        const result = await configurationService.deleteSharedMailbox(
          userId,
          mailboxId,
        );
        if (result) {
          dispatch({type: 'SET_USER_CONFIG', payload: result});
        }
      } finally {
        setShowErrors(false);
      }
    }
  };

  const handleAdd = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!isValidEmail) {
      setShowErrors(true);
      return;
    }

    setIsLoading(true);
    try {
      const newMailbox = {
        id: Guid.create().toString(),
        label: mailboxAddress,
        address: mailboxAddress,
      };

      const result = await configurationService.postSharedMailbox(
        userId,
        newMailbox,
      );
      if (result) {
        dispatch({type: 'SET_USER_CONFIG', payload: result});
      }
      closeModal();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <EuiPanel paddingSize="l">
      <EuiTitle size="s">
        <h2>{t('Configuration.SharedMailboxes.Title')}</h2>
      </EuiTitle>
      <EuiSpacer size="l" />
      <EuiText>
        <p>{t('Configuration.SharedMailboxes.Text')}</p>
      </EuiText>
      <EuiSpacer size="l" />
      <EuiFlexGroup gutterSize="s" alignItems="flexStart" wrap>
        <EuiButton
          iconType="plusInCircle"
          size="s"
          color="primary"
          onClick={() => setIsModalVisible(true)}
          fill
        >
          {t('Configuration.SharedMailboxes.AddButton')}
        </EuiButton>
      </EuiFlexGroup>
      <EuiSpacer size="l" />
      <SharedMailboxTable
        mailboxes={userConfig?.SharedMailboxes || []}
        onDeleteMailbox={handleDelete}
      />

      {isModalVisible && (
        <AddMailboxModal
          isVisible={isModalVisible}
          isLoading={isLoading}
          showErrors={showErrors}
          mailboxAddress={mailboxAddress}
          isValidEmail={isValidEmail}
          onClose={closeModal}
          onSubmit={handleAdd}
          onChange={handleEmailChange}
        />
      )}
    </EuiPanel>
  );
};
