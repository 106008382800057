import {EuiModal, EuiModalBody} from '@elastic/eui';
import {ResultViewProps} from '@elastic/react-search-ui-views';

import {EasyDetailCard} from './cards/EasyDetailCard';

interface DetailsModalProps {
  resultView: ResultViewProps;
  isOpen: boolean;
  onClose: () => void;
}

export const DetailsModal: React.FC<DetailsModalProps> = ({
  resultView,
  isOpen,
  onClose,
}) => {
  if (!isOpen) return null;

  return (
    isOpen && (
      <EuiModal
        onClose={onClose}
        key={`m_${resultView.result.id?.raw}`}
        className="easy-modal-no-header"
      >
        <EuiModalBody className="easy-modal__body">
          <EasyDetailCard resultView={resultView} />
        </EuiModalBody>
      </EuiModal>
    )
  );
};
