import {
  EuiBadge,
  EuiDescriptionList,
  EuiFlexGroup,
  EuiFlexItem,
  EuiPanel,
  EuiSpacer,
  EuiTitle,
} from '@elastic/eui';
import {ResultViewProps} from '@elastic/react-search-ui-views';
import {Button, Tooltip} from '@fluentui/react-components';
import {CallRegular, ChatRegular, VideoRegular} from '@fluentui/react-icons';
import {useTranslation} from 'react-i18next';

import {DeepLinkActionType} from '../../common/enums';
import {deepLinkHandler} from '../../handlers/deepLinkHandler';
import {EasyUser} from '../EasyUser';
import {FavoriteStar} from '../FavoriteStar';
import {EasyCardLine} from './EasyCardLine';

interface EasyDetailCardProps {
  resultView: ResultViewProps;
}

export const EasyDetailCard: React.FC<EasyDetailCardProps> = ({resultView}) => {
  const {t} = useTranslation();

  return (
    <>
      <div style={{width: '100%'}}>
        <EuiPanel hasBorder={true}>
          <div className="sui-result__header">
            <EasyUser
              userId={resultView.result.id?.raw}
              displayName={resultView.result.display_name?.raw}
            />
            <EuiFlexItem className="sui-result__header-center">
              <EuiTitle size="xs">
                <h1>{resultView.result.display_name?.raw}</h1>
              </EuiTitle>
              <EuiFlexGroup className="action-container">
                <EuiFlexItem grow={false}>
                  <Tooltip
                    content={t('Main.Card.StartChat')}
                    relationship="description"
                    positioning={'below'}
                  >
                    <Button
                      appearance="transparent"
                      size="small"
                      icon={<ChatRegular />}
                      onClick={() =>
                        deepLinkHandler(
                          resultView.result.mail?.raw,
                          DeepLinkActionType.TeamsChat,
                        )
                      }
                    />
                  </Tooltip>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <Tooltip
                    content={t('Main.Card.StartVideoCall')}
                    relationship="description"
                    positioning={'below'}
                  >
                    <Button
                      appearance="transparent"
                      size="small"
                      icon={<VideoRegular />}
                      onClick={() =>
                        deepLinkHandler(
                          resultView.result.mail?.raw,
                          DeepLinkActionType.TeamsVideoCall,
                        )
                      }
                    />
                  </Tooltip>
                </EuiFlexItem>
                <EuiFlexItem grow={false}>
                  <Tooltip
                    content={t('Main.Card.StartCall')}
                    relationship="description"
                    positioning={'below'}
                  >
                    <Button
                      appearance="transparent"
                      size="small"
                      icon={<CallRegular />}
                      onClick={() =>
                        deepLinkHandler(
                          resultView.result.mail?.raw,
                          DeepLinkActionType.TeamsCall,
                        )
                      }
                    />
                  </Tooltip>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
            <EuiFlexItem className="sui-result__header-end" grow={false}>
              <FavoriteStar
                contactId={resultView.result.id?.raw}
                displayName={resultView.result.display_name?.raw}
              />
            </EuiFlexItem>
          </div>
        </EuiPanel>
        <div className="sui-result__body">
          <EuiSpacer size="m" />
          <EuiFlexGroup direction="column" wrap={true} style={{gap: '0'}}>
            {resultView.result.job_title?.raw ||
            resultView.result.company_name?.raw ? (
              <div style={{marginTop: '.5rem'}}>
                {resultView.result.job_title?.raw && (
                  <EuiFlexItem>{resultView.result.job_title?.raw}</EuiFlexItem>
                )}
                {resultView.result.company_name?.raw && (
                  <EuiFlexItem>
                    {resultView.result.company_name?.raw}
                  </EuiFlexItem>
                )}{' '}
              </div>
            ) : null}
            <EuiFlexItem className="sui-result__body-wrapper">
              <EuiDescriptionList
                textStyle="reverse"
                compressed
                type="row"
                listItems={[
                  resultView.result.department?.raw && {
                    title: 'Department',
                    description: (
                      <span className="easy-contact-description__text">
                        {resultView.result.department?.raw}
                      </span>
                    ),
                  },
                  resultView.result.office_location?.raw && {
                    title: 'Office Location',
                    description: (
                      <span className="easy-contact-description__text">
                        {resultView.result.office_location?.raw}
                      </span>
                    ),
                  },
                  resultView.result.mail?.raw && {
                    title: t('Main.Card.Email'),
                    description: (
                      <EasyCardLine
                        iconType="email"
                        copyEnabled
                        onCopy={() => {
                          navigator.clipboard.writeText(
                            resultView.result.mail?.raw,
                          );
                        }}
                        clickEnabled
                        onClick={() =>
                          deepLinkHandler(
                            resultView.result.mail?.raw,
                            DeepLinkActionType.Email,
                          )
                        }
                      >
                        {resultView.result.mail?.raw}
                      </EasyCardLine>
                    ),
                  },
                  resultView.result.business_phones?.raw && {
                    title: t('Main.Card.BusinessPhone'),
                    description: (
                      <EasyCardLine
                        iconType="shard"
                        copyEnabled
                        onCopy={() => {
                          navigator.clipboard.writeText(
                            resultView.result.business_phones?.raw,
                          );
                        }}
                        clickEnabled
                        onClick={() =>
                          deepLinkHandler(
                            resultView.result.business_phones?.raw,
                            DeepLinkActionType.PSTNCall,
                          )
                        }
                      >
                        {resultView.result.business_phones?.raw}
                      </EasyCardLine>
                    ),
                  },
                  resultView.result.mobile_phone?.raw && {
                    title: t('Main.Card.MobilePhone'),
                    description: (
                      <EasyCardLine
                        iconType="mobile"
                        copyEnabled
                        onCopy={() => {
                          navigator.clipboard.writeText(
                            resultView.result.mobile_phone?.raw,
                          );
                        }}
                        clickEnabled
                        onClick={() =>
                          deepLinkHandler(
                            resultView.result.mobile_phone?.raw,
                            DeepLinkActionType.PSTNCall,
                          )
                        }
                      >
                        {resultView.result.mobile_phone?.raw}
                      </EasyCardLine>
                    ),
                  },
                  resultView.result.street_address?.raw &&
                    resultView.result.postal_code?.raw &&
                    resultView.result.city?.raw && {
                      title: t('Main.Card.BusinessAddress'),
                      description: (
                        <EasyCardLine
                          iconType="mapMarker"
                          clickEnabled
                          onClick={() =>
                            deepLinkHandler(
                              `${resultView.result.street_address?.raw},
                          ${resultView.result.postal_code?.raw}${' '}
                          ${resultView.result.city?.raw}`,
                              DeepLinkActionType.GoogleMaps,
                            )
                          }
                        >
                          <span>
                            {resultView.result.street_address?.raw} <br />
                            {resultView.result.postal_code?.raw}{' '}
                            {resultView.result.city?.raw}
                          </span>
                        </EasyCardLine>
                      ),
                    },
                  resultView.result.country?.raw && {
                    title: t('Main.Card.Country'),
                    description: resultView.result.country?.raw ?? '',
                  },
                  {
                    title: t('Main.Card.Tags'),
                    description: resultView.result.tags?.raw.map(
                      (tag: string) => <EuiBadge key={tag}>{tag}</EuiBadge>,
                    ),
                  },
                ].filter(Boolean)}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      </div>
    </>
  );
};
