import React, {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';

import {PersonalView, UserConfiguration} from '../common/interfaces';

export type EasySearchContextType = {
  selectedView: PersonalView | undefined;
  // onSelectedViewChange: (view: PersonalView | undefined) => void;
  setSelectedView: Dispatch<SetStateAction<PersonalView | undefined>>;
  userConfig: UserConfiguration | undefined;
  setUserConfig: React.Dispatch<
    React.SetStateAction<UserConfiguration | undefined>
  >;
};

const EasySearchContext = createContext<EasySearchContextType | undefined>(
  undefined,
);

export const EasySearchProvider = ({children}: {children: ReactNode}) => {
  const [selectedView, setSelectedView] = useState<PersonalView | undefined>();
  const [userConfig, setUserConfig] = useState<UserConfiguration>();

  // const handleSelectedViewChange = useCallback(
  //   (view: PersonalView | undefined) => {
  //     setSelectedView(view);
  //   },
  //   []
  // );

  const value = useMemo(() => {
    return {
      selectedView,
      setSelectedView,
      userConfig,
      setUserConfig,
    };
  }, [selectedView, userConfig]);

  return (
    <EasySearchContext.Provider value={value}>
      {children}
    </EasySearchContext.Provider>
  );
};

export const useEasySearchProvider = (): EasySearchContextType => {
  const context = useContext(EasySearchContext);
  if (!context) {
    throw new Error(
      'useEasySearchProvider must be used within an EasySearchProvider',
    );
  }
  return context;
};
