import {EuiFlexGroup, EuiFlexItem, EuiPanel} from '@elastic/eui';
import {SearchBox} from '@elastic/react-search-ui';
import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';

import {EasyContext} from '../EasyContext';
import EasyCustomView from './EasyCustomView';

const EasySearchHeader: React.FC = () => {
  const {t} = useTranslation();
  const {userConfig} = useContext(EasyContext);

  return (
    <EuiPanel paddingSize="m">
      <EuiFlexGroup direction="row" alignItems="center" wrap={false}>
        <EuiFlexItem>{userConfig && <EasyCustomView />}</EuiFlexItem>
        <EuiFlexItem>
          <SearchBox
            autocompleteSuggestions={true}
            debounceLength={0}
            inputView={({getAutocomplete, getInputProps, getButtonProps}) => (
              <>
                <div className="sui-search-box__wrapper">
                  <input
                    {...getInputProps({
                      placeholder: t('Menu.SearchField'),
                    })}
                  />
                  {getAutocomplete()}
                </div>
                <input {...getButtonProps()} value={t('Button.Search')} />
              </>
            )}
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </EuiPanel>
  );
};

export default EasySearchHeader;
