import withEasySearchProvider from '../contexts/withEasySearchProvider';
import {Search} from '../pages/search/Search';

/**
 * WrappedSearch is a version of the Search component that is wrapped with the EasySearchProvider.
 * This ensures that the EasySearch context is available to the Search component and its sub-components.
 */
const WrappedSearch = withEasySearchProvider(Search);

export default WrappedSearch;
