import {
  Dropdown,
  makeStyles,
  Option,
  OptionGroup,
} from '@fluentui/react-components';
import {
  Cloud20Regular,
  Delete20Regular,
  Edit20Regular,
  StackStar20Regular,
  Star20Filled,
  Star20Regular,
} from '@fluentui/react-icons';
import {tokens} from '@fluentui/react-theme';
import React, {useContext, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';

import {PersonalView} from '../../common/interfaces';
import {useEasySearchProvider} from '../../contexts/EasySearchContext';
import {ConfigurationService} from '../../services/ConfigurationService';
import {EasyContext} from '../EasyContext';
import DeleteViewModal from '../modals/DeleteViewModal';
import EditViewModal from '../modals/EditViewModal';
import FavoriteViewModal from '../modals/FavoriteViewModal';

const useStyles = makeStyles({
  optionContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '90%',
  },
  optionText: {
    flexGrow: 1,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    marginRight: '8px',
  },
  beforeIconsContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingRight: '8px',
  },
  iconsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    marginLeft: 'auto',
  },
});

type EasyViewDropdownProps = {
  views: Array<PersonalView>;
  orgViews: Array<PersonalView>;
  defaultView: string;
  changeDefaultView: (id: string, label: string) => void;
  deleteView: (view: PersonalView) => void;
  editView: (view: PersonalView, newLabel: string) => void;
  reset: () => void;
  setViews: React.Dispatch<React.SetStateAction<Array<PersonalView>>>;
};

export const EasyViewDropdown: React.FC<EasyViewDropdownProps> = ({
  views,
  orgViews,
  deleteView,
  defaultView,
  changeDefaultView,
  editView,
}) => {
  const {t} = useTranslation();
  const {selectedView, setSelectedView} = useEasySearchProvider();
  const {userConfig} = useContext(EasyContext);
  const styles = useStyles();

  const easyContext = useContext(EasyContext);

  const configurationService = useMemo(() => {
    return new ConfigurationService(easyContext.accessToken);
  }, [easyContext.accessToken]);

  const [selectedKey, setSelectedKey] = useState<string | undefined>(
    selectedView?.id,
  );

  const [selectedValue, setSelectedValue] = useState<string | undefined>(
    selectedView?.label,
  );

  const [showFavoriteModal, setShowFavoriteModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [viewToDelete, setViewToDelete] = useState<PersonalView | null>(null);
  const [viewToEdit, setViewToEdit] = useState<PersonalView | null>(null);
  const [viewToFavorite, setViewToFavorite] = useState<PersonalView | null>(
    null,
  );

  useEffect(() => {
    setSelectedKey(selectedView?.id);
    setSelectedValue(selectedView?.label);
  }, [selectedView]);

  const getFavorites = async () => {
    try {
      const updatedConfig = await configurationService.getUserConfiguration(
        easyContext.userId,
      );

      if (updatedConfig?.Favorites) {
        return updatedConfig.Favorites;
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error updating favorites:', error);
    }
  };

  const updateFavorites = async (favorites: string[]) => {
    easyContext.dispatch({
      type: 'UPDATE_FAVORITES',
      payload: favorites,
    });
  };

  const handleFavouritesView = async () => {
    const favouriteIds = await getFavorites();

    await updateFavorites(favouriteIds ?? []);

    const favouritesView: PersonalView = {
      id: 'favourites_view',
      label: t('Views.Dropdown.FavoritesText'),
      searchTemplate: JSON.stringify({
        searchTerm: '',
        filters: [
          {
            field: 'id',
            values: favouriteIds,
            type: 'any',
          },
        ],
      }),
    };

    setSelectedView(favouritesView);
  };

  const handleSynchedContactsView = () => {
    const synchedContactIds = userConfig?.SynchedContacts || [];

    const synchedContactsView: PersonalView = {
      id: 'synched_contacts_view',
      label: t('Views.Dropdown.SynchedText'),
      searchTemplate: JSON.stringify({
        searchTerm: '',
        filters: [
          {
            field: 'id',
            type: 'any',
            values: synchedContactIds,
          },
        ],
      }),
      pageOptions: {
        presenceView: false,
        groupedBy: undefined,
        fullScreen: false,
      },
    };

    setSelectedView(synchedContactsView);
  };

  const getStarIcon = (view: PersonalView) => {
    return defaultView === view.id ? (
      <Star20Filled
        primaryFill={tokens.colorPaletteGoldBorderActive}
        onClick={(event) => {
          event.stopPropagation();
          setShowFavoriteModal(true);
          setViewToFavorite(view);
        }}
      />
    ) : (
      <Star20Regular
        primaryFill={tokens.colorPaletteGoldBorderActive}
        onClick={(event) => {
          event.stopPropagation();
          setShowFavoriteModal(true);
          setViewToFavorite(view);
        }}
      />
    );
  };

  const onOptionSelect = (
    event: React.SyntheticEvent,
    data: {optionValue: string | undefined},
  ) => {
    const selectedKey = data.optionValue;
    setSelectedKey(selectedKey);

    if (selectedKey === 'favourites_view') {
      setSelectedView(undefined);
      setSelectedValue(t('Views.Dropdown.FavoritesText'));
      handleFavouritesView();
    } else if (selectedKey === 'synched_contacts') {
      setSelectedView(undefined);
      setSelectedValue(t('Views.Dropdown.SynchedText'));
      handleSynchedContactsView();
    } else {
      const view = views.find((v) => v.id === selectedKey);
      if (view) {
        setSelectedView(view);
        setSelectedValue(view.label);
      } else if (orgViews.find((v) => v.id === selectedKey)) {
        const orgView = orgViews.find((v) => v.id === selectedKey);
        if (orgView) {
          setSelectedView(orgView);
          setSelectedValue(orgView.label);
        } else {
          setSelectedView(undefined);
          setSelectedValue(undefined);
        }
      }
    }
  };

  return (
    <>
      <Dropdown
        placeholder={selectedValue || t('Views.Dropdown.Title')}
        selectedOptions={selectedKey ? [selectedKey] : []}
        onOptionSelect={onOptionSelect}
        size="large"
        style={{minWidth: '350px'}}
      >
        <div
          style={{maxHeight: '250px', overflowY: 'auto', overflowX: 'hidden'}}
        >
          <OptionGroup label={t('Views.Dropdown.Headers.SystemViews')}>
            <Option
              value="favourites_view"
              text={t('Views.Dropdown.FavoritesText')}
            >
              <div className={styles.optionContent}>
                <div className={styles.beforeIconsContainer}>
                  <StackStar20Regular
                    primaryFill={tokens.colorPaletteBlueForeground2}
                  />
                </div>
                <span
                  className={styles.optionText}
                  title={t('Views.Dropdown.FavoritesText')}
                >
                  {t('Views.Dropdown.FavoritesText')}
                </span>
                <div className={styles.iconsContainer}>
                  <Star20Regular
                    primaryFill={tokens.colorPaletteGoldBorderActive}
                  />
                </div>
              </div>
            </Option>
            <Option
              value="synched_contacts"
              text={t('Views.Dropdown.SynchedText')}
            >
              <div className={styles.optionContent}>
                <div className={styles.beforeIconsContainer}>
                  <Cloud20Regular
                    primaryFill={tokens.colorPaletteBlueForeground2}
                  />
                </div>
                <span
                  className={styles.optionText}
                  title={t('Views.Dropdown.SynchedText')}
                >
                  {t('Views.Dropdown.SynchedText')}
                </span>
                <div className={styles.iconsContainer}>
                  <Star20Regular
                    primaryFill={tokens.colorPaletteGoldBorderActive}
                  />
                </div>
              </div>
            </Option>
          </OptionGroup>
          <OptionGroup label={t('Views.Dropdown.Headers.OrgViews')}>
            {orgViews.map((v) => (
              <Option key={v.id} value={v.id} text={v.label}>
                <div className={styles.optionContent}>
                  <span className={styles.optionText} title={v.label}>
                    {v.label}
                  </span>
                  <div className={styles.iconsContainer}>{getStarIcon(v)}</div>
                </div>
              </Option>
            ))}
          </OptionGroup>
          <OptionGroup label={t('Views.Dropdown.Headers.PersonalViews')}>
            {views.map((v) => (
              <Option key={v.id} value={v.id} text={v.label}>
                <div className={styles.optionContent}>
                  <span className={styles.optionText} title={v.label}>
                    {v.label}
                  </span>
                  <div className={styles.iconsContainer}>
                    {getStarIcon(v)}
                    <Edit20Regular
                      primaryFill={tokens.colorPaletteBlueForeground2}
                      onClick={(event) => {
                        event.stopPropagation();
                        setShowEditModal(true);
                        setViewToEdit(v);
                      }}
                    />
                    <Delete20Regular
                      primaryFill={tokens.colorPaletteRedForeground3}
                      onClick={(event) => {
                        event.stopPropagation();
                        setShowDeleteModal(true);
                        setViewToDelete(v);
                      }}
                    />
                  </div>
                </div>
              </Option>
            ))}
          </OptionGroup>
        </div>
      </Dropdown>

      {showFavoriteModal && viewToFavorite && (
        <FavoriteViewModal
          isDefaultMarked={defaultView === viewToFavorite.id}
          onClose={() => {
            setShowFavoriteModal(false);
            setViewToFavorite(null);
          }}
          onSubmit={async (isDefaultMarked: boolean) => {
            await changeDefaultView(
              isDefaultMarked ? 'reset' : viewToFavorite.id,
              viewToFavorite.label,
            );
            setShowFavoriteModal(false);
            setViewToFavorite(null);
          }}
        />
      )}

      {showDeleteModal && viewToDelete && (
        <DeleteViewModal
          onClose={() => {
            setShowDeleteModal(false);
            setViewToDelete(null);
          }}
          onDelete={async () => {
            await deleteView(viewToDelete);
            setShowDeleteModal(false);
            setViewToDelete(null);
          }}
        />
      )}

      {showEditModal && viewToEdit && (
        <EditViewModal
          view={viewToEdit}
          onClose={() => {
            setShowEditModal(false);
            setViewToEdit(null);
          }}
          onSave={async (newLabel: string) => {
            await editView(viewToEdit, newLabel);
            setShowEditModal(false);
            setViewToEdit(null);
          }}
        />
      )}
    </>
  );
};
