import {
  EuiButton,
  EuiFieldText,
  EuiForm,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
} from '@elastic/eui';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';

interface AddMailboxModalProps {
  isVisible: boolean;
  isLoading: boolean;
  showErrors: boolean;
  mailboxAddress: string;
  isValidEmail: boolean;
  onClose: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AddMailboxModal: FC<AddMailboxModalProps> = ({
  isVisible,
  isLoading,
  showErrors,
  mailboxAddress,
  isValidEmail,
  onClose,
  onSubmit,
  onChange,
}) => {
  const {t} = useTranslation();

  if (!isVisible) return null;

  return (
    <EuiModal aria-labelledby="Add Shared Mailbox" onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          {t('Configuration.SharedMailboxes.AddButton')}
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiForm
          component="form"
          isInvalid={showErrors}
          error={
            showErrors
              ? [t('Configuration.SharedMailboxes.Form.EmailError')]
              : undefined
          }
          onSubmit={onSubmit}
        >
          <EuiSpacer size="m" />
          <EuiFieldText
            placeholder={t(
              'Configuration.SharedMailboxes.Form.MailboxAddressPlaceholder',
            )}
            aria-label={t(
              'Configuration.SharedMailboxes.Form.MailboxAddressPlaceholder',
            )}
            value={mailboxAddress}
            onChange={onChange}
            isInvalid={showErrors}
          />
          <EuiSpacer size="m" />
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButton onClick={onClose} color="text">
          {t('Configuration.SharedMailboxes.Buttons.Cancel')}
        </EuiButton>

        <EuiButton
          type="submit"
          fill
          isDisabled={!isValidEmail}
          isLoading={isLoading}
        >
          {t('Configuration.SharedMailboxes.Buttons.Save')}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default AddMailboxModal;
