import {
  EuiButton,
  EuiFieldText,
  EuiForm,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiSpacer,
} from '@elastic/eui';
import {FC} from 'react';
import {useTranslation} from 'react-i18next';

interface AddTagModalProps {
  isVisible: boolean;
  isLoading: boolean;
  tag: string;
  onClose: () => void;
  onSubmit: (e: React.FormEvent<HTMLFormElement>) => Promise<void>;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const AddTagModal: FC<AddTagModalProps> = ({
  isVisible,
  isLoading,
  tag,
  onClose,
  onSubmit,
  onChange,
}) => {
  const {t} = useTranslation();

  if (!isVisible) return null;

  return (
    <EuiModal aria-labelledby="Add Shared Mailbox" onClose={onClose}>
      <EuiModalHeader>
        <EuiModalHeaderTitle>
          {t('Configuration.TagsManager.AddButton')}
        </EuiModalHeaderTitle>
      </EuiModalHeader>

      <EuiModalBody>
        <EuiForm component="form" onSubmit={onSubmit}>
          <EuiSpacer size="m" />
          <EuiFieldText
            placeholder={t('Configuration.TagsManager.Form.Placeholder')}
            aria-label={t('Configuration.TagsManager.Form.Placeholder')}
            value={tag}
            onChange={onChange}
          />
          <EuiSpacer size="m" />
        </EuiForm>
      </EuiModalBody>
      <EuiModalFooter>
        <EuiButton onClick={onClose} color="text">
          {t('Configuration.TagsManager.Buttons.Cancel')}
        </EuiButton>

        <EuiButton
          type="submit"
          fill
          isLoading={isLoading}
          disabled={tag.length === 0}
        >
          {t('Configuration.TagsManager.Buttons.Save')}
        </EuiButton>
      </EuiModalFooter>
    </EuiModal>
  );
};

export default AddTagModal;
