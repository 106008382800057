import {ReactNode} from 'react';

import {useEasyPageOptionsProvider} from '../../contexts/EasyPageOptionsContext';

interface EasyBodyContentProps {
  children: ReactNode;
}

const EasyBodyContentView: React.FC<EasyBodyContentProps> = ({children}) => {
  const {pageOptions} = useEasyPageOptionsProvider();
  const cardLayoutClass = pageOptions.presenceView
    ? 'ed-view-presence'
    : 'ed-view-standard';

  return <div className={cardLayoutClass}>{children}</div>;
};

export default EasyBodyContentView;
