import {
  EuiCard,
  EuiFlexGroup,
  EuiFlexItem,
  EuiIcon,
  EuiSpacer,
} from '@elastic/eui';

export function Welcome() {
  return (
    <div className="tcf-welcome-cards">
      <EuiSpacer size="xxl" />
      <EuiSpacer size="xxl" />
      <EuiSpacer size="xxl" />
      <EuiFlexGroup gutterSize="l">
        <EuiFlexItem>
          <EuiCard
            icon={<EuiIcon type="heartbeatApp" size="xl" />}
            title="Favorites"
            description="Manage your favorites"
          />
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiCard
            icon={<EuiIcon type="advancedSettingsApp" size="xl" />}
            title="Settings"
            description="Manage your settings"
          />
        </EuiFlexItem>
      </EuiFlexGroup>
    </div>
  );
}
